import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)
// 职位
const userRole = {
  0: '普通成员',
  1: '管理员',
  3: '社长'
}
const userRoleOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '社长',
    value: 3
  },
  {
    text: '管理员',
    value: 1
  },
  {
    text: '普通成员',
    value: 0
  }
]
// 身份
const userType = {
  1: '业主',
  2: '家属',
  3: '租户',
  4: '伙伴',
  5: '嘉宾'
}

// 审核状态
const auditStatusType = {
  0: '待审核',
  1: '已通过',
  2: '未通过',
}

const auditStatusSelectOps = [
  {
    text: '待审核',
    value: 0
  },
  {
    text: '已通过',
    value: 1
  },
  {
    text: '未通过',
    value: 2
  }
]

const auditStatusOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '待审核',
    value: 0
  },
  {
    text: '已通过',
    value: 1
  },
  {
    text: '未通过',
    value: 2
  }
]

export {
  statusMap,
  setStatusOps,
  auditStatusSelectOps,
  userRole,
  userType,
  userRoleOps,
  auditStatusType,
  auditStatusOps,
}
