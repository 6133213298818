<template>
  <div class="MembersList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :exportUrl="exportUrl"
      :isMultiSelect="false"
    >
    <template #headerSlot>
        <v-button permission="memberadd" text="新增" @click="toAddPages" />
        <v-button permission="import" text="导入" @click="batchImport" />
        <v-button
          text="返回"
          @click="backAction"
        ></v-button>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.mobileNum"></v-input>
        <v-select label="职位" v-model="searchParams.userRole" :options="userRoleOps"></v-select>
        <v-datepicker label="加入时间" :maxDate="maxDate" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
        <v-select label="审核伏态" v-model="searchParams.status" :options="auditStatusOps"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button permission="memberAudit" v-if="scope.row.status === 0" text="审核" type="text"  @click="toAduitPage(scope.row)"></v-button>
        <v-button text="删除" type="text" v-if="scope.row.userRole !== 3" @click="memberTrash(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, deletedURL } from './api'
import { userRole, userType, userRoleOps, auditStatusType, auditStatusOps } from './map'

export default {
  name: 'MembersList',
  data () {
    return {
      searchUrl: `${getListURL}` + `?groupId=` + this.$route.query.id,
      exportUrl: `${exportListURL}` + `?groupId=` + this.$route.query.id,
      userRoleOps: userRoleOps,
      auditStatusOps: auditStatusOps,
      maxDate: new Date(),
      searchParams: {
        userName: '',
        userRole: undefined,
        mobileNum: '',
        startTime: '',
        endTime: '',
        status: undefined,
      },
      headers: [
        {
          prop: 'nikeName',
          label: '昵称'
        },
        {
          prop: 'userName',
          label: '姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'userRole',
          label: '职位',
          formatter (row) {
            return userRole[row.userRole]
          }
        },
        {
          prop: 'userType',
          label: '身份',
          formatter (row) {
            return userType[row.userType]
          }
        },
        {
          prop: 'roomInfo',
          label: '房号信息'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'joinTime',
          label: '加入时间'
        },
        {
          prop: 'status',
          label: '审核状态',
          formatter (row) {
            return auditStatusType[row.status]
          }
        }
      ],
      neighborClubId: undefined,
      companyName: undefined,
    }
  },
  created () {
    this.$setBreadcrumb('社群成员')
    const { id, companyName } = this.$route.query
    this.neighborClubId = id;
    this.companyName = companyName;
  },
  methods: {
    // 返回按钮
    backAction () {
      this.$router.go(-1)
    },
    async memberTrash (row) {
      let isOk = await this.$confirm('确认删除?')
      isOk && this.deletedData(row)
    },
    deletedData (row) {
      let _this = this
      _this.$axios.put(deletedURL(row.id)).then(res => {
        let returnStatus = String(res.status)
        if (returnStatus === '100') {
          _this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
          _this.$refs.list.searchData()
        }
      })
    },
    // 跳转到新增页面
    toAddPages() {
      this.$router.push({
        name: "MemberAddNew",
        query: {
          neighborClubId: this.neighborClubId,
          companyName: this.companyName,
        }
      });
    },
    // 跳转到导入页面
    batchImport() {
      this.$router.push({
        name: "MemberImport",
        query: {
          groupId: this.$route.query.id
        }
      });
    },
    // 跳转到审核页面
    toAduitPage(row) {
      console.log(row, '++++++')
      this.$router.push({
        name: "MemberAduit",
        query: {
          neighborClubId: row.id,
        }
      });
    }
  }
}
</script>
