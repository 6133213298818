var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "MembersList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          exportUrl: _vm.exportUrl,
          isMultiSelect: false,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { permission: "memberadd", text: "新增" },
                  on: { click: _vm.toAddPages },
                }),
                _c("v-button", {
                  attrs: { permission: "import", text: "导入" },
                  on: { click: _vm.batchImport },
                }),
                _c("v-button", {
                  attrs: { text: "返回" },
                  on: { click: _vm.backAction },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "姓名" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "手机号" },
                  model: {
                    value: _vm.searchParams.mobileNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "mobileNum", $$v)
                    },
                    expression: "searchParams.mobileNum",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "职位", options: _vm.userRoleOps },
                  model: {
                    value: _vm.searchParams.userRole,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userRole", $$v)
                    },
                    expression: "searchParams.userRole",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "加入时间",
                    maxDate: _vm.maxDate,
                    startTime: _vm.searchParams.startTime,
                    endTime: _vm.searchParams.endTime,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "startTime", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "endTime", $event)
                    },
                  },
                }),
                _c("v-select", {
                  attrs: { label: "审核伏态", options: _vm.auditStatusOps },
                  model: {
                    value: _vm.searchParams.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "status", $$v)
                    },
                    expression: "searchParams.status",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: {
                        permission: "memberAudit",
                        text: "审核",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.toAduitPage(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.userRole !== 3
                  ? _c("v-button", {
                      attrs: { text: "删除", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.memberTrash(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }